import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import Loader from "./Components/Loader";
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/Styles/styles.scss";

const App = lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<Loader />}>
    <App />
  </Suspense>
);
